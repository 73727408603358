<template>
  <div>
    <!-- 地图容器 -->
    <div id="map" ref="mapContainer" class="map-container">
      <!-- 控制按钮组 -->
      <div class="map-controls">
        <div class="map-assistant">
          <h1>地图助手</h1>
          <input v-model="userInput" @keydown.enter="sendRequest" placeholder="请输入命令" />
          <button @click="sendRequest">发送请求</button>
          <div v-if="response">
            <p>系统响应：{{ response }}</p>
          </div>
        </div>
        <div class="map-control-button mark-button" @click.stop="enableMarking">
          📝 <span class="tooltip">添加标记</span>
        </div>
        <!-- 添加区域按钮 -->
        <div class="map-control-button area-button" @click.stop="enableDrawingArea">
          ✏️ <span class="tooltip">绘制区域</span>
        </div>
        <!-- 漫游按钮 -->
        <div class="map-control-button pan-button" @click="panToLocation([34.81732, 113.535807])">
          ➤ <span class="tooltip">返回视图中心ZZU</span>
        </div>
        <!-- 定位按钮 -->
        <div class="map-control-button locate-button" @click="locateUser">
          📍 <span class="tooltip">显示我的当前位置</span>
        </div>
        <!-- 放大按钮 -->
        <div class="map-control-button zoom-in-button" @click="zoomIn">
          ➕ <span class="tooltip">放大地图</span>
        </div>
        <!-- 缩小按钮 -->
        <div class="map-control-button zoom-out-button" @click="zoomOut">
          ➖ <span class="tooltip">缩小地图</span>
        </div>
      </div>

      <!-- 搜索框 -->
      <div class="map-search-container">
        <input
          type="text"
          v-model="searchQuery"
          @input="handleSearchInput"
          placeholder="查找地点..."
          class="map-search-box"
        />
        <div v-if="searchResults.length > 0" class="search-results">
          <ul>
            <li v-for="(result, index) in searchResults" :key="index" @click="selectSearchResult(result)">
              {{ result.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 编辑要素信息弹框 -->
    <div v-if="isEditing" class="remark-modal">
      <h2>编辑要素信息</h2>
      <textarea v-model="currentRemark" rows="5" placeholder="在此输入要素信息..."></textarea>
      <button @click="saveRemark">保存</button>
      <button @click="cancelEdit">取消</button>
    </div>
    <!-- 编辑要素信息弹框 -->
    <div v-if="isEditingArea" class="area-editor-popup">
      <div class="popup-content">
        <label for="feature-type">选择要素类型：</label>
        <select id="feature-type" v-model="currentAreaDetails.featureType">
          <option value="土地">土地</option>
          <option value="建筑">建筑</option>
          <option value="水体">水体</option>
          <option value="自然要素">自然要素</option>
          <option value="自定义">自定义</option>
        </select>
        <div>
          <label for="remark">输入要素信息：</label>
          <textarea id="remark" v-model="currentRemark" rows="5" placeholder="在此输入要素信息..."></textarea>
        </div> 
        <div class="popup-buttons">
          <button class="popup-btn save" @click="saveAreaDetails">保存</button>
          <button class="popup-btn cancel" @click="cancelAreaEdit">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-control-geocoder';
import { debounce } from 'lodash';
import axios from 'axios';



export default {
  name: 'MapView',
  data() {
    return {
      userMarker: null,
      map: null,
      searchQuery: '',
      searchResults: [],
      amapKey: 'b3bcdffd3f9e29bc4f0af243323d4b41', // 高德 API Key
      endPointQuery: '',
      userInput: '',
      response: '',
      isMarking: false,
      isDrawingArea: false, 
      currentRemark: '',
      currentLat: 0,
      currentLng: 0,
      isEditing: false,
      activeArea: null,  // 当前编辑的区域
      currentAreaDetails: '',  // 当前区域的详情
      isEditingArea: false,  // 编辑框显示的状态
      activeMarker: null, // 当前选中的标记
      markers: [], // 保存所有标记信息，存储到后端
      areas: [], // 保存所有绘制的区域
      locateIcon: L.icon({
        iconUrl: require('@/assets/Locate.jpg'),
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30]
      }),
      zzuIcon: L.icon({
        iconUrl: require('@/assets/zzu.jpg'),
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -30]
      }),

      baseLayer: {
        '高德地图底图': L.tileLayer("https://webrd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}", { attribution: "高德", maxZoom: 20 }),
        '卫星影像地图': L.tileLayer("https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png", { attribution: "卫星影像", maxZoom: 20 }),
        'OpenStreetMap': L.tileLayer("http://10.87.211.254:8099/title/{z}/{x}/{y}.png", { attribution: "白色底图", maxZoom: 20 }),
        '天地图影像图': L.tileLayer("http://t3.tianditu.com/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=d0cf74b31931aab68af181d23fa23d8d", { maxZoom: 20 }),
        '天地图街道图': L.tileLayer("http://t4.tianditu.com/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=d0cf74b31931aab68af181d23fa23d8d", { maxZoom: 20 })
      }
    };
  },
  mounted() {
    this.loadMarkers(); // 页面加载时加载存储的标记数据
    this.initMap(); // 初始化地图
  },
  methods: {
    initMap() {
      // 初始化地图
      this.map = L.map(this.$refs.mapContainer, {
        center: [34.81732, 113.535807],
        zoom: 15,
        zoomAnimation: false,// 禁用缩放动画
        zoomControl: false, // 自定义缩放控件
      });

      // 添加底层图层
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap',
        maxZoom: 20
      }).addTo(this.map);

      // 添加默认的郑州大学标记
      this.addMarker([34.81732, 113.535807], this.zzuIcon, '<b>郑州大学</b>');

      // 强制刷新地图尺寸
      this.map.invalidateSize();

      // 设置地图的最大和最小缩放级别
      this.map.setMaxZoom(18);
      this.map.setMinZoom(3);

      // 添加图层切换控件
      L.control.layers(this.baseLayer, {}, { position: 'topleft' }).addTo(this.map);

      this.map.on('dblclick',this.stopDrawingArea);

    },
    


    panToLocation(coordinates) {
      this.map.panTo(coordinates);
    },

    enableMarking(){
      this.isMarking = true;
      this.isDrawingArea = false;
      this.map.on('click', this.addMarker_poi);
    },

    enableDrawingArea(){ 
      this.isDrawingArea = true;
      this. isMarking = false;
      this.currentArea = null;
      this.map.on('click', this.addArea);
    },


    addMarker_poi(event){
      if (!this.isMarking) return;

      const latlng = event.latlng;
      const marker = L.marker(latlng, {icon: this.locateIcon}).addTo(this.map);
      marker.bindPopup('<b>Point</b><br>点击编辑备注');

      // 保存标记
      this.markers.push({
        latlng: latlng,
        marker: marker,
        remark: '',
        id: null
      });
      // 添加点击事件，当点击标记时询问是否删除
      marker.on('click', () => {
        const confirmDelete = window.confirm('确认保留该标记吗？');
        if (!confirmDelete) {
          this.deleteMarker(marker);
        } else {
          this.openRemarkEditor(marker); // 如果用户不删除，继续编辑标记
        }
      });
    },

    deleteMarker(marker){
       // 从地图中移除标记
      this.map.removeLayer(marker);

      // 从 markers 数组中移除该标记
      const index = this.markers.findIndex(m => m.marker === marker);
      if (index !== -1) {
        this.markers.splice(index, 1);
      }
      /*const markerData = this.markers.find(m => m.marker === marker);
  
      if (markerData && markerData.id) {
        axios.delete(`http://localhost:3001/api/markers/${markerData.id}`)
          .then(response => {
            console.log('标记删除成功:', response.data);
            // 从地图中移除标记
            this.map.removeLayer(marker);
            // 从 markers 数组中移除该标记
            const index = this.markers.findIndex(m => m.marker === marker);
            if (index !== -1) {
              this.markers.splice(index, 1);
            }
          })
          .catch(error => {
            console.error('删除标记失败:', error);
          });
      } else {
        console.log('该标记没有 ID, 无法删除');
      }*/
    },

    addArea(event) {
      if (!this.isDrawingArea) return; // 如果当前不是绘制状态，则不做任何操作

      const latlng = event.latlng; // 获取点击的坐标

      // 检查是否点击了已存在的区域
        const clickedArea = this.areas.find(area => area.getBounds().contains(latlng));

      if (clickedArea) {
        // 如果点击的是已有区域，停止绘制区域
        this.isDrawingArea = false;
        this.map.off('click', this.addArea); // 移除点击事件监听，停止绘制
        return;
      }

      // 如果当前已经有绘制中的区域，并且至少有两个点，继续添加点
      if (!this.currentArea) {
        this.currentArea = new L.Polygon([latlng], { color: 'pink', fillOpacity: 0.4 }).addTo(this.map);
        this.areas.push(this.currentArea);
        this.currentArea.on('click', this.onAreaClick.bind(this));
      } else {
        this.currentArea.addLatLng(latlng);
      }

      
      L.circle(latlng, {
        radius: 6,  // 圆的半径
        color: '#ff4081',  // 边框颜色
        fillColor: '#ffeb3b',  // 填充颜色
        fillOpacity: 0.6,  // 更高的透明度
        weight: 3,  // 边框宽度
        opacity: 0.7,  // 边框透明度
        dashArray: '5, 5', 
        className: 'circle-shadow'  // 为圆形添加阴影样式
      }).addTo(this.map);


      // 当区域点数达到3个后，继续点击当前标记点时停止绘制
      if (this.currentArea.getLatLngs().length > 3) {
        this.cancelAreaEdit();
        this.openFeaturePopup(this.currentArea); // 打开要素框
      }

      // 结束绘制区域：判断多边形至少有5个点时，自动结束绘制
      //if (this.currentArea.getLatLngs().length >= 5) {
        //this.finishDrawingArea();
      //}
    },

    openRemarkEditor(marker) {
      this.activeMarker = marker;
      // 通过 id 获取该标记的备注，确保编辑框显示当前备注
      const markerData = this.markers.find(m => m.marker === marker);
      this.currentRemark = markerData ? markerData.remark : '';
      this.isEditing = true;

    },

    saveRemark() {
      const markerData = this.markers.find(m => m.marker === this.activeMarker);
      if (markerData) {
        markerData.remark = this.currentRemark; // 更新本地数据
        const postData = {
          lat: this.activeMarker.getLatLng().lat,
          lng: this.activeMarker.getLatLng().lng,
          remark: this.currentRemark
        };
        
        console.log('保存的数据:', postData);  // 打印保存的数据

        if (markerData.id !== null) {
          // 更新已有标记
          axios.post('http://localhost:3001/api/markers', postData)
            .then(response => {
              console.log('标记更新成功:', response.data);
              this.isEditing = false;
              this.currentRemark = '';
            })
            .catch(error => {
              console.error('更新标记失败:', error);
            });
        } else {
          // 添加新标记
          axios.post('http://localhost:3001/api/markers', postData)
            .then(response => {
              console.log('新标记保存成功:', response.data);
              markerData.id = response.data.id;  // 保存 id
              this.isEditing = false;
              this.currentRemark = '';
            })
            .catch(error => {
              console.error('保存新标记失败:', error);
            });
        }
      }
    },

    loadMarkers() {
      axios.get('http://localhost:3001/api/markers')
        .then(response => {
          console.log('从数据库加载标记数据', response.data)
          this.markers = response.data;  // 将返回的标记数据保存到组件的 markers 数组
          // 在地图上显示标记
          this.markers.forEach(markerData => {
            const marker = L.marker([markerData.lat, markerData.lng], {icon: this.locateIcon}).addTo(this.map);
            marker.bindPopup('<b>Point</b><br>' + markerData.remark);
          });
        })
        .catch(error => {
          console.error('加载标记数据失败:', error);
        });
    },
    // 在地图中添加标记
    /*addMarkerToMap() {
      this.markers.forEach(marker => {
        // 在这里可以使用 Leaflet 或其他地图组件来渲染标记
        L.marker([marker.lat, marker.lng]).addTo(this.map)
          .bindPopup(`<b>备注:</b> ${marker.remark}`);
      });
    },*/

    cancelEdit() {
      this.isEditing = false;
      this.activeMarker = null;
      this.currentRemark = '';
    },

    /*getMarkerRemark(marker) {
      const markerData = this.markers.find(m => m.marker === marker);
      return markerData ? markerData.remark : '';
    },*/

    // 在选定区域点击时弹出要素框
    onAreaClick(event) {
      const area = event.target; // 获取被点击的区域对象

      // 询问用户是否保留该区域
      const confirmDelete = window.confirm('确认保留该区域吗？');
      if (!confirmDelete) {
        this.deleteArea(area); // 用户选择删除区域
      } else {
        this.openAreaEditor(area); // 用户选择编辑区域
      }
    },

    openAreaEditor(area) {
      console.log('编辑区域：', area);
      // 在这里可以打开区域编辑的弹出框或其他界面
      this.activeArea = area;
      const areaData = this.areas.find(a => a === area);
      this.currentAreaDetails = areaData ? areaData.details : {};
      this.isEditingArea = true;

    },

    saveAreaDetails() {
      if (this.activeArea) {
        const areaData = this.areas.find(a => a === this.activeArea);


        if (areaData) {
          areaData.details = this.currentAreaDetails; // 更新区域详情
        }
      }
      this.isEditingArea = false;
      this.activeArea = null;
      this.currentAreaDetails = {};
    },

    cancelAreaEdit() {
      this.isEditingArea = false;
      this.activeArea = null;
      this.currentAreaDetails = {};
    },

    // 删除区域
    deleteArea(area) {
      this.map.removeLayer(area);

      // 从区域数组中移除该区域
      const index = this.areas.findIndex(a => a === area);
      if (index !== -1) {
        this.areas.splice(index, 1);
      }
    },

    zoomIn() {
      if (this.map) {
        let zoomLevel = this.map.getZoom();
        const maxZoom = this.map.getMaxZoom();
        if (zoomLevel < maxZoom) {
          this.map.setZoom(zoomLevel + 1);
          this.map.invalidateSize();
        }
      }
    },

    zoomOut() {
      if (this.map) {
        let zoomLevel = this.map.getZoom();
        const minZoom = this.map.getMinZoom();
        if (zoomLevel > minZoom) {
          this.map.setZoom(zoomLevel - 1);
          this.map.invalidateSize();
        }
      }
    },
    /*定位 */
    locateUser() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const accuracy = position.coords.accuracy;

            if (this.userMarker) {
              this.map.removeLayer(this.userMarker);
            }

            this.userMarker = this.addMarker([lat, lng], this.locateIcon, `
              <strong>您的位置</strong><br>经度: ${lng}<br>纬度: ${lat}<br>精度: ${accuracy} 米
            `);

            this.map.panTo([lat, lng]);
          },
          error => {
            console.error("定位失败", error);
            alert("定位失败，请检查您的定位权限设置");
          }
        );
      } else {
        alert("浏览器不支持定位功能");
      }
    },
    // 搜索输入功能
    handleSearchInput: debounce(function() {
      if (this.searchQuery.length > 2) {
        this.searchWithAmapAPI(this.searchQuery);
      }else{
        this.searchResults = [];
      } 
    }, 500),

    //选择搜索结果并视点移动到该位置，调用高德API
    async searchWithAmapAPI(query) {
      try {
        const response = await axios.get('https://restapi.amap.com/v3/place/text', {
          params: {
            key: this.amapKey,
            keywords: query,
            city: '全国', 
            output: 'json',
          },
        });

        if (response.data.status === '1') {
          // 解析高德API返回的地点列表
          this.searchResults = response.data.pois.map((poi) => ({
            name: poi.name,
            location: poi.location.split(',').reverse().map(Number), // 经纬度转为 [lat, lng] 格式
            address: poi.address,
          }));
        } else {
          this.searchResults = [];
        }
      } catch (error) {
        console.error('高德API调用失败', error);
      }
    },
    async sendRequest() {
      if (!this.userInput) return;
      
      try {
        // 向后端发送用户的自然语言输入
        console.log('用户输入:', this.userInput);

        const res = await fetch('http://116.204.91.224:3355/process_command', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ command: this.userInput }),
        });
        
        if (!res.ok) {
          console.error('请求失败，状态码：', res.status);
          return;
        }

        const data = await res.json();
        console.log('收到响应:', data);

        if(data.function_name){
          this.executeMapAction(data.function_name, data.parameters);
        }else{
          console.error('后端返回的响应不包含 function_name 字段:', data);
        }
      } catch (error) {
        console.error('请求失败', error);
      }
    },
    
    executeMapAction(functionName, parameters){
      switch(functionName){
        case 'move_map':
          this.moveMap(parameters.location);
          break;
        case 'zoom_in_map':
          this.zoomInMap(parameters.level);
          break;
        case 'zoom_out_map':
          this.zoomOutMap(parameters.level);
          break;
        default:
          console.error('未知的函数:', functionName);
      }
    },

    zoomInMap(level){
      console.log('地图放大级别:', level);
      this.map.zoomIn(level);
    },

    zoomOutMap(level){
      console.log('地图缩小级别:', level);
      this.map.zoomOut(level);
    },

    async moveMap(location) {
      console.log('准备移动地图到位置:', location);
  
      try {
        // 调用高德地图地理编码 API 获取位置的经纬度
        const coordinates = await this.getCoordinatesFromAddress(location);

        if (coordinates) {
          // 如果获取到经纬度，则移动地图
          this.panToLocation(coordinates);
        } else {
          console.log('无法获取该位置的经纬度');
        }
      } catch (error) {
        console.error('获取地理位置时出错:', error);
      }
    },

    async getCoordinatesFromAddress(address) {
      const apiKey = 'b3bcdffd3f9e29bc4f0af243323d4b41';  
      const url = `https://restapi.amap.com/v3/geocode/geo?address=${encodeURIComponent(address)}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error('地理编码请求失败，状态码:', response.status);
          return null;
        }

        const data = await response.json();
        if (data.status === '1' && data.geocodes && data.geocodes.length > 0) {
          // 获取第一个结果的经纬度
          const { location } = data.geocodes[0];
          const [lng, lat] = location.split(',');
          return [parseFloat(lat), parseFloat(lng)];
        } else {
          console.log('未找到该地址的经纬度');
          return null;
        }
      } catch (error) {
        console.error('地理编码请求出错:', error);
        return null;
      }
    }, 
    // 选择搜索结果
    selectSearchResult(result) {
      const latlng = result.location;
      this.searchResults = [];
      this.searchQuery = result.name;

      this.$nextTick(() => {
        this.map.panTo(latlng);
        this.addMarker(latlng, this.locateIcon, `<b>${result.name}</b><br>${result.address || ''}`);
      });

    },
    
    // 添加地图标记
    addMarker(latlng, icon, popupContent) {
      const marker = L.marker(latlng, { icon })
        .addTo(this.map)
        .bindPopup(popupContent)
        .openPopup();
      return marker;
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100vh;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  z-index: 1;
}
/* 控制按钮定位 */
.map-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.map-control-button {
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.map-control-button:hover {
  background: linear-gradient(135deg, #5a75e3, #7d6bb3);
}

.tooltip {
  font-size: 14px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: -150%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.map-control-button:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.map-search-container {
  position: absolute;
  top: 70px;
  left: 10px;
  z-index: 1000;
  width: 250px;
}

.map-search-box {
  width: 100%;
  padding: 12px;
  border-radius: 25px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.map-search-box:focus {
  outline: none;
  border-color: #6e8efb;
  box-shadow: 0 0 10px rgba(110, 143, 251, 0.5);
}

.search-results {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.search-results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-results li:hover {
  background-color: #f1f1f1;
}

.map-route-container {
  position: absolute;
  top: 110px;
  left: -1720px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'KaiTi', '楷体', sans-serif;
}

.route-input {
  width: 200px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.route-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.route-b utton:hover {
  background-color: #0056b3;
}

.transport-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.transport-button {
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.transport-button img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.transport-button:hover {
  background-color: #e1e1e1;
  border-color: #bbb;
}

.transport-button.active {
  background-color: #007bff;
  border-color: #0056b3;
  color: white;
}

.transport-button.active img {
  filter: brightness(0) invert(1);
}

.map-route-container p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'KaiTi', '楷体', sans-serif; 
}

.map-assistant {
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom of the screen */
  right: 20px;  /* Adjust the distance from the right of the screen */
  background-color: rgba(255, 255, 255, 0.8); /* Light background with transparency */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for better visibility */
  font-family: "KaiTi", "楷体", sans-serif; /* Set the font to KaiTi */
  z-index: 1000; /* Ensure the assistant is on top */
}

.map-assistant h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.map-assistant input,
.map-assistant button {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.map-assistant button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.map-assistant button:hover {
  background-color: #0056b3;
}

.map-assistant p {
  color: #007bff;
}
.remark-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.remark-modal textarea {
  width: 100%;
  margin-bottom: 20px;
}

.remark-modal button {
  margin-right: 10px;
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remark-modal button:hover {
  background-color: #45a049;
}

.area-editor-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.area-editor-popup select,
.area-editor-popup textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.area-editor-popup button {
  margin-right: 10px;
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.area-editor-popup button:hover {
  background-color: #45a049;
}

.area-editor-popup .popup-btn.cancel {
  background-color: #f44336;
}

.area-editor-popup .popup-btn.cancel:hover {
  background-color: #e53935;
}

</style>
